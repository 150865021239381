import React, { useState } from "react";
import { NavBarButtons } from "./navbarButtons";

export const NavBar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="bg-white md:top-24 xl:mx-2 border sticky rounded-2xl">
      <nav className="md:flex md:items-center">
        <div className="md:hidden w-full">
          <button
            className="p-2 rounded-2xl focus:outline-none float-right focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            aria-expanded={isMenuOpen}
            onClick={toggleMenu}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
        <div className={`${isMenuOpen ? "block" : "hidden"} md:block`}>
          <NavBarButtons />
        </div>
      </nav>
    </div>
  );
};
