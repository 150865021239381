import React, { useMemo } from "react";
import { Level } from "./Level";
import { useGetLevelsQuery } from "@services/api/apiSlice";
import { PageLayout } from "@layout/page-layout";
import ActionButton from "@buttons/actionButton";
import { EntityPreview } from "@elements/entityPreview";
import { useAccount } from "@features/accounts/useAccount";

const LevelsPage: React.FC = () => {
  const { account } = useAccount();
  console.log("Account in LevelsPage:", account?.name);
  const { data: levels = [], error, isLoading } = useGetLevelsQuery(account?.account || "");

  let content: React.ReactNode;

  const sortedLevels = useMemo(() => {
    const sortedLevels = levels.slice();
    // Sort levels by name
    sortedLevels.sort((a, b) => b.name.localeCompare(a.name));
    return sortedLevels;
  }, [levels]);

  if (isLoading) {
    content = <div>Loading...</div>;
  } else if (error) {
    content = <div>Error: {error.toString()}</div>;
  } else {
    content =
      sortedLevels.length > 0 ? (
        <ul className="lg:mr-24 flex flex-col gap-4">
          {sortedLevels.map((level: Level) => (
            <li
              className="flex flex-row text-[#212121] bg-white gap-2 mx-2 shadow-[#9E9E9E] border border-gray-300 rounded-2xl p-4 justify-center"
              key={level.id}
            >
              <EntityPreview entityType="levels" entity={level} />
            </li>
          ))}
        </ul>
      ) : (
        <p className="error text-lg font-semibold">
          No levels found for this account.
        </p>
      );
  }

  return (
    <PageLayout>
      <>
        <div className="flex lg:mr-24 max-w-full p-6 justify-items items-center">
          <h1 className="mb-2 text-2xl mr-auto font-semibold text-gray-900">
            Levels
          </h1>
          <ActionButton label="Create Level" to="/levels/create">
            Create level
          </ActionButton>
        </div>
        <section className="levels-list">{content}</section>
      </>
    </PageLayout>
  );
};

export default LevelsPage;
