import React, { useMemo } from "react";
import { Account } from "./Account";
import { PageLayout } from "@layout/page-layout";
import { useGetAccountsQuery } from "@services/api/apiSlice";
import { EntityPreview } from "@elements/entityPreview";
import { ExpandableProvider } from "@buttons/expandableButtons";
import Loader from "@elements/loader";
import { useAccount } from "./useAccount";

const AccountsPage: React.FC = () => {
  const { account } = useAccount();
  console.log("Account in AccountsPage:", account?.name);
  const { data: accounts = [], error, isLoading } = useGetAccountsQuery();

  // Robert: this is the strucure for loading data like lists
  let content: React.ReactNode;

  // Robert: this is the way to sort elements, like by name
  const sortedAccounts = useMemo(() => {
    const sortedAccounts = accounts.slice();
    // Sort accounts by name
    sortedAccounts.sort((a, b) => b.name.localeCompare(a.name));
    return sortedAccounts;
  }, [accounts]);

  if (isLoading) {
    <Loader />;
  } else if (error) {
    // Robert: maybe using a toast here
    content = <div>Error: {error.toString()}</div>;
  } else {
    content = (
      <PageLayout>
        <section className="accounts-list">
          <div className="flex max-w-full lg:mr-24 p-6 justify-items items-center">
            <h1 className="mb-2 text-2xl mr-auto font-semibold text-gray-900">
              Accounts
            </h1>
          </div>
          <ExpandableProvider>
            <ul className="lg:mr-24 flex flex-col gap-4">
              {sortedAccounts.map((account: Account) => (
                <li
                  className="flex flex-row text-[#212121] bg-white gap-2 mx-2 shadow-[#9E9E9E] border border-gray-300 rounded-2xl p-4 justify-center"
                  key={account.account}
                >
                  <EntityPreview entityType="accounts" entity={account} />
                </li>
              ))}
            </ul>
          </ExpandableProvider>
        </section>
      </PageLayout>
    );
  }

  return <>{content}</>;
};

export default AccountsPage;
