import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetEventsQuery,
  useAddEventMutation, // add an event
  useUpdateEventMutation, // update an event
  useUploadImageMutation, // upload image
} from "@services/api/apiSlice";

import {
  NameInput,
  DescriptionInput,
  IdInput,
  CategoryInput,
  TagsInput,
  IsAvailableInput,
  RestrictedCountInput,
  IsLimitInput,
} from "@fields/Fields";

import { useAccount } from "@/features/accounts/useAccount";

import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Link } from "@/components/ui/link";

import RewardsPicker from "@pickers/rewardsPicker";

import { Event, defaultEvent } from "./Event";
import { PageLayout } from "@layout/page-layout";

import ImagePicker from "@pickers/imagePicker";

const EventPage: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const { account } = useAccount();
  const navigate = useNavigate();
  const { data: events = [], isLoading } = useGetEventsQuery(
    account?.account || ""
  );

  // Note! We can provide eventId in either url params or using props for it
  // Current approach is using url params now
  const [isNew, setIsNew] = useState<boolean>(false);

  const [addNewEvent] = useAddEventMutation();
  const [updateEvent] = useUpdateEventMutation();

  const [event, setEvent] = useState<Event>({ ...defaultEvent });

  const [errors, setErrors] = useState<Partial<Record<keyof Event, string>>>(
    {}
  );

  console.log("Account in EventPage:", account?.name);

  // Image uploadi
  const [file, setFile] = useState<File | null>(null);
  const [uploadImage] = useUploadImageMutation();

  useEffect(() => {
    console.log(`Account => ${account}`);
  }, [account]);

  useEffect(() => {
    console.log("in useEffect");
    console.log(`Account => ${account}`);

    if (eventId == "new") {
      setIsNew(true);
      setEvent({
        ...defaultEvent,
        account: account?.account || "",
      });
    } else {
      const eventToEdit = events.find((event) => event.id === eventId);
      // Navigate to Events if account is missing or mismatch.
      if (!account || eventToEdit && eventToEdit.account !== account.account) {
        navigate("/events");
      }
      if (eventToEdit) {
        setIsNew(false);
        console.log(JSON.stringify(eventToEdit));
        setEvent(eventToEdit);
      } else {
        // Note! This should never happen
        //console.error(`Event with id ${eventId} not found`);
        //navigate("/events");
      }
    }
  }, [events, eventId, navigate, account]);

  const updateField = <K extends keyof Event>(field: K, value: Event[K]) => {
    setEvent((prev) => ({ ...prev, [field]: value }));
    // Clear error when field is updated
    if (errors[field]) {
      setErrors((prev) => ({ ...prev, [field]: undefined }));
    }
  };

  useEffect(() => {
    console.log(`Updated event: ${JSON.stringify(event)}`);
  }, [event]);

  //console.log(isNew, JSON.stringify(event));

  // First the image needs to be saved. saveImage
  // returns the path to the stored image which needs
  // to be provided when then element is saved
  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    const accountId = account?.account || "";
    if (validateForm()) {
      if (isNew) {
        try {
          const path = await saveImage(accountId, file, event.imgUrl);
          await addNewEvent({ ...event, imgUrl: path }).unwrap();
        } catch (err) {
          console.log("Failed to save event or image");
        }
        setIsNew(false);
      } else {
        // update event
        try {
          const path = await saveImage(accountId, file, event.imgUrl);
          await updateEvent({ ...event, imgUrl: path }).unwrap();
          console.log(`Saved: ${JSON.stringify(event)}`);
        } catch (err) {
          console.log("Failed to save event or image");
        }
      }
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Image uploading
  const handleImagePicked = (file: File) => {
    if (file) {
      setFile(file);
    }
  };

  const saveImage = async (
    account: string,
    file: File | null,
    imgUrl: string
  ): Promise<string> => {
    if (!file || !account) return imgUrl;

    const formData = new FormData();
    formData.append("image", file);

    try {
      const result = await uploadImage({ formData, account }).unwrap();
      return result.path;
    } catch (error) {
      console.error("Failed to upload image:", error);
    }
    // In case failing saved, we just return the old imgUrl
    return imgUrl;
  };

  const validateForm = (): boolean => {
    const newErrors: Partial<Record<keyof Event, string>> = {};
    if (!event.id.trim()) newErrors.id = "Id is required";
    if (!event.name.trim()) newErrors.name = "Name is required";
    console.log(newErrors);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <PageLayout>
      <div>
        <Link to={"/events"} variant="withIcon">
          {"Back"}
        </Link>
        <form className="mx-4 lg:mr-24 p-5 bg-neutral-100 rounded-3xl">
          <Label className="text-2xl font-bold my-6">
            {event && event.name ? event.name : "Create new event"}
          </Label>
          <div className="grid grid-cols-2 mt-4 gap-4">
            <div className="col-span-1">
              <IsAvailableInput
                value={event.isAvailable}
                onChange={(value) => updateField("isAvailable", value)}
              />
            </div>
            <div className="col-span-1"></div>

            <div className="col-span-1">
              <NameInput
                entity_name="Event"
                value={event.name}
                required
                onChange={(value) => updateField("name", value)}
                placeholder="Give your Event a name"
                error={errors.name}
              />
            </div>

            <div className="col-span-1">
              <IdInput
                entity_name="Event"
                value={event.id}
                required
                onChange={(value) => updateField("id", value)}
                placeholder="Give your Event a unique ID"
                disabled={!isNew}
                error={errors.id}
              />
            </div>

            <div className="col-span-1">
              <DescriptionInput
                value={event.description}
                onChange={(value) => updateField("description", value)}
                placeholder="You can also give your Event a description here"
              />
            </div>
            <div className="col-span-1">
              <ImagePicker
                path={event?.imgUrl}
                id={event?.id}
                onImagePicked={handleImagePicked}
                size="small"
              />
            </div>

            <div className="col-span-2 grid grid-cols-2 md:grid-cols-4 gap-4">
              <CategoryInput
                value={event.category}
                onChange={(value) => updateField("category", value)}
              />
              <TagsInput
                value={event.tags}
                onChange={(value) => updateField("tags", value)}
              />
              <div className="flex flex-col justify-end h-full">
                <RestrictedCountInput
                  value={event.countLimit}
                  onChange={(value) => updateField("countLimit", value)}
                  placeholder="Limit number of completions"
                  disabled={!event.limitCount}
                />
              </div>
              <div className="flex items-stretch h-9 mt-auto">
                <IsLimitInput
                  value={event.limitCount}
                  onChange={(value) => updateField("limitCount", value)}
                />
              </div>
            </div>
            <div className="col-span-2 my-4 border border-primary-200 border-t-0 border-r-0 border-l-0" />
            <div className="col-span-2">
              <RewardsPicker
                reward={event.reward}
                className="rounded-2xl border border-primary-200 bg-white"
                onChange={(reward) => {
                  console.log(`onChange => ${JSON.stringify(reward)}`);
                  updateField("reward", reward);
                }}
              />
            </div>
          </div>
          <Button
            className="float-right uppercase py-6 px-16 my-16"
            type="submit"
            onClick={handleSave}
          >
            Save
          </Button>
        </form>
      </div>
    </PageLayout>
  );
};

export default EventPage;
