import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const LoginButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/accounts",
      },
      authorizationParams: {
        prompt: "login",
        //connection: "email",
      },
    });
  };

  return (
    <button
      className="flex-1 bg-blue-100 hover:bg-blue-300 text-blue-500 uppercase py-4 px-4 rounded-full"
      onClick={handleLogin}
    >
      I already have an account
    </button>
  );
};
