import React from "react";

import ActionButton from "@buttons/actionButton";
import { useAccount } from "@/features/accounts/useAccount";
import { ExpandableProvider } from "@buttons/expandableButtons";
import { PageLayout } from "@layout/page-layout";
import { useGetEventsQuery } from "@services/api/apiSlice";
import { EntityPreview } from "@elements/entityPreview";
import Loader from "@elements/loader";

/**
 * Represents the Events Page component.
 * This component displays a list of events and allows users to create, edit, delete, and duplicate events.
 */
const EventsPage: React.FC = () => {
  // Robert: later on, instead of "gltest", a selected account id should be used
  // selected account would to saved in the Redux store
  //const [account, setAccount] = useState<Account | null>(null);
  const { account } = useAccount();
  console.log("Account in EventsPage:", account?.name);
  const {
    data: events = [],
    error,
    isLoading,
  } = useGetEventsQuery(account?.account || "");
  let content = null;

  if (isLoading) {
    content = <Loader />;
  } else if (!isLoading && !error) {
    content =
      events &&
      events.map((event) => (
        <li
          key={event.id}
          className="flex flex-row text-[#212121] bg-white gap-2 mx-2 shadow-[#9E9E9E] border border-gray-300 rounded-2xl p-4"
        >
          <EntityPreview entityType="events" entity={event} />
        </li>
      ));
  } else if (error) {
    content = <div>{error.toString()}</div>;
  }

  return (
    <PageLayout>
      <section className="posts-list">
        <div className="flex lg:mr-24 max-w-full p-6 justify-items items-center">
          <h1 className="mb-2 text-2xl mr-auto font-semibold text-gray-900">
            Events
          </h1>
          <div className="flex flex-row gap-4">
            <ActionButton to="/events/new">Create Event</ActionButton>
          </div>
        </div>
        <ExpandableProvider>
          <ul className="lg:mr-24 flex flex-col gap-4">{content}</ul>
        </ExpandableProvider>
      </section>
    </PageLayout>
  );
};

export default EventsPage;
