import { useMemo } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

export type ListItem = {
  id: string;
  name: string;
  disabled: boolean;
};

interface ListPickerProps {
  currentValue?: string;
  items: ListItem[];
  listType: string;
  className?: string;
  placeholder?: string;
  onSelect: (item: ListItem) => void;
}

export function ListPicker({
  currentValue,
  placeholder,
  items,
  onSelect,
  className,
  listType }: ListPickerProps) {
  const sortedItems = useMemo(() => {
    return [...items].sort((a, b) => a.name.localeCompare(b.name));
  }, [items]);
  const currentItem = sortedItems.find((item) => item.id === currentValue);
  const handleSelect = (value: string) => {
    const item = sortedItems.find((i) => i.id === value);
    if (item) {
      onSelect(item);
    }
  };

  return (
    <div className={className}>
      <Select onValueChange={handleSelect}>
        <SelectTrigger>
          <SelectValue
            placeholder={currentItem?.name || placeholder ? placeholder : `Select a ${listType}`} />
        </SelectTrigger>
        <SelectContent>
          {sortedItems.map((item) => (
            <SelectItem key={item.id} value={item.id} disabled={item.disabled}>
              {item.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
