import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Link } from "react-router-dom";
import { LogoutButton } from "@buttons/logoutButton";

export const NavBarButtons: React.FC = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <div className="nav flex flex-col gap-2 m-4">
      <Link to="/">Home</Link>
      <Link to="/accounts">Accounts</Link>
      <Link to="/events">Events</Link>
      <Link to="/levels">Levels</Link>
      <hr />
      {isAuthenticated && (
        <LogoutButton />
      )}
    </div>
  );
};
