import React, { useState, useEffect } from "react";

import { useGetAchievementsQuery } from "@services/api/apiSlice";

import { Rewards } from "../../features/common/Common";
import { PointsInput, CreditsInput } from "../fields/Fields";
import { ListPicker, ListItem } from "@pickers/listPicker";
import ItemEdit from "@pickers/itemEdit";

import { Achievement } from "@/features/achievements/Achievement";
import { AchievementSteps } from "@/features/common/Common";

interface RewardPickerProps {
  reward: Rewards;
  className?: string;
  onChange: (value: Rewards) => void;
}

const account = "gltest";

const mergeLists = (
  listA: AchievementSteps[],
  listB: Achievement[]
): Achievement[] => {
  return listA
    .filter((itemA) => listB.some((itemB) => itemB.id === itemA.id))
    .map((itemA) => {
      const itemB = listB.find((b) => b.id === itemA.id)!;
      return {
        ...itemB,
        steps: itemA.steps, // Keep steps from A
      };
    });
};

const RewardsPicker: React.FC<RewardPickerProps> = ({ reward, className, onChange }) => {
  const { data: achievements = [], isLoading } =
    useGetAchievementsQuery(account);
  const [selection, setSelection] = useState<Achievement[]>([]);
  const [listItems, setListItems] = useState<ListItem[]>([]);

  useEffect(() => {
    console.log("Populating items");

    if (!isLoading && achievements.length > 0 && reward.achievements) {
      // Create list of selected items based on achievements
      const sel = mergeLists(reward.achievements, achievements);
      console.log(JSON.stringify(sel));

      // Only update state if there's an actual change

      setSelection((prevSelection) =>
        JSON.stringify(prevSelection) !== JSON.stringify(sel)
          ? sel
          : prevSelection
      );
    }
  }, [isLoading, achievements, reward.achievements]);

  useEffect(() => {
    if (!isLoading && achievements.length > 0) {
      const seList = achievements.map((item) => {
        return {
          id: item.id,
          name: item.name,
          disabled: selection.some((item2) => item2.id === item.id),
        };
      });
      setListItems(seList);
    }
  }, [selection, achievements, isLoading]);

  const handleSelect = (item: ListItem) => {
    if (isLoading) return;

    const achItem = achievements.find((e) => e.id === item.id);
    if (achItem == null) return;
    setSelection((prevSelected) => {
      // Check if the event is already selected
      const isAlreadySelected = prevSelected.some((e) => e.id === item.id);

      if (isAlreadySelected) {
        // If it's already selected, remove it from the list
        return prevSelected.filter((e) => e.id !== item.id);
      } else {
        // If it's not selected, add it to the list
        return [...prevSelected, achItem];
      }
    });
  };

  const achRewards = (selection: Achievement[]) => {
    return selection.map((item) => {
      return { id: item.id, steps: item.steps };
    });
  };

  const updateSelection = (updatedSelection: Achievement[]) => {
    setSelection(updatedSelection);
    onChange({
      ...reward,
      achievements: achRewards(updatedSelection),
    });
  };
  const onDelete = (id: string) => {
    const updatedSelection = selection.filter((item) => item.id !== id);
    updateSelection(updatedSelection);
  };

  const onValueChange = (id: string, value: number) => {
    //if (isLoading) return;
    const updatedSelection = selection.map((item) =>
      item.id === id ? { ...item, steps: value } : item
    );
    updateSelection(updatedSelection);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Rewards</h2>

      <div className="grid grid-cols-2 gap-4">
        <div className="col-start-1 row-start-1">
          <PointsInput
            value={reward.points}
            onChange={(points) => {
              onChange({ ...reward, points });
            }}
          />
        </div>
        <div className="col-start-2 row-start-1">
          <CreditsInput
            value={reward.credits}
            onChange={(credits) => onChange({ ...reward, credits })}
          />
        </div>
        <div className="col-start-1 row-start-2">
          <ListPicker
            items={listItems}
            className={className}
            listType="Achievement"
            onSelect={handleSelect}
          />
        </div>
        <div className="col-start-2 row-start-2 space-y-4">
          {selection.map((item: Achievement) => (
            <ItemEdit
              key={item.id}
              id={item.id}
              imgUrl={item.imgUrl}
              title="Achievement Name"
              name={item.name}
              countTitle="Steps Granted"
              count={item.steps}
              onChange={(id, value) => onValueChange(id, value)}
              onDelete={(id) => onDelete(id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RewardsPicker;

/*
<fieldset className="grid grid-cols-2 gap-4">
        <PointsInput
          value={reward.points}
          onChange={(points) => {
            onChange({ ...reward, points });
          }}
        />
        <CreditsInput
          value={reward.credits}
          onChange={(credits) => onChange({ ...reward, credits })}
        />
      </fieldset>

*/
