import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App.tsx";
import { store } from "./store/store";
import { Provider } from "react-redux";

import "./index.css";
import { Auth0ProviderWithNavigate } from "./services/auth/auth0-provider.tsx";
import { AccountProvider } from "@features/accounts/AccountProvider.tsx";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <AccountProvider>
          <Auth0ProviderWithNavigate>
            <App />
          </Auth0ProviderWithNavigate>
        </AccountProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
