import React from "react";
import { NavBar } from "@navigation/navbar";
import Logo from "@elements/logo";
import AccountPicker from "@pickers/accountPicker";

interface Props {
  children: React.ReactNode;
}

export const PageLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen bg-white"> {/* Use flex-col for mobile-first */}
      <header className="flex flex-row p-4 bg-white shadow">
        <div className="flex flex-row gap-2 items-center flex-1 xl:max-w-[1200px] xl:m-auto">
          <Logo src={null} alt="GameLayer" />
          <span className="text-[#212121] text-2xl">GameLayer</span>
        </div>
        <div>
          <AccountPicker  />
        </div>
      </header>

      <main className="flex container max-w-full xl:max-w-[1200px] xl:m-auto flex-col md:flex-row lg:pt-6"> {/* Main content takes remaining space */}
        <aside className="bg-white md:bg-transparent sticky top-0 md:relative flex-1 md:max-w-48 w-full md:w-64 md:p-4 z-40"> {/* Sidebar */}
          <NavBar />
        </aside>

        <div className="flex-1 min-w-64 w-full"> {/* Main content area */}
          {children}
        </div>
      </main>

      <footer className="bg-primary-500 mt-8 p-4 text-white text-sm text-center">
        <div className="flex gap-2 items-center xl:max-w-[1200px] xl:m-auto">
          <Logo type="inverted" src={null} alt="GameLayer" />
          <span>GameLayer</span>
        </div>
      </footer>
    </div>
  );
};
